.accueil_section{
    min-height: 100vh;
    .section_header{
        .bloc{
            position: relative;
            z-index: 1;
            .logo-container {
                text-align: center; /* Centrer le contenu à l'intérieur */
                
              }
              
              .logo {
                max-width: 100%; /* Assurez-vous que le logo ne dépasse pas sa taille d'origine */
                // animation: pulsate 1s infinite;
                
              }
             
            .sectionimage{
               
                background-image:  linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7)),url('../../public/img/imageheader.png'); 
                height: 86vh;
                display: flex;
                align-items: center;
                text-align: center;
                color: #f7f8fa;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                // border-radius: 0px 0px 300px 300px;
                .container{
                    padding-top: 200px;
                    .text1{
                        // padding-top: 70px;
                        padding: 0 2rem;
                        font-size: 1.5rem;
                        color: $secondary;
                        font-family: 'SpaceGrotesk Bold';
                        position: relative;
                        display: inline;
                        &::before {
                            position: absolute;
                            top: 50%;
                            bottom: 50%;
                            left: 0;
                            content: '';
                            height: 1.5px;
                            width: 30px;
                            background: #fff;
                            margin-right: 10px;
                            transform: translateY(-50%);
                        } 
                        &::after {
                            position: absolute;
                            top: 50%;
                            bottom: 50%;
                            right: 0;
                            content: '';
                            height: 1.5px;
                            width: 30px;
                            background: #fff;
                            margin-left: 10px;
                            transform: translateY(-50%);
                        } 
                    }
                    
                    .text2{
                        font-size: 3rem;
                        color: white;
                        font-family: 'SpaceGrotesk Bold';
                        .styletext{
                            color:$secondary;
                        }
                        
                    }
                    .text3{
                        font-size: 1.8rem;
                        color: white;
                        font-family: 'SpaceGrotesk Medium';
                        .text3sous{
                            color: $secondary;
                        }
                        
                    }
                    .text4{
                        font-family: 'SpaceGrotesk Bold';
                    }
                    @media (max-width: 768px){
                        .text1{
                            font-size: .8rem;
                        }
                        .text2{
                            font-size: 1rem;
                        }
                        .text3{
                         font-size: .7rem;
                        }
                        .text4{
                            font-size: .7rem;
                        }
                    }
                }
              
                
               
            }
        }
        .overlay-image {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            z-index: 2;
          }

    }
    .section_presentation{
        background-color: #fff;
        .row{
            .titre{
                padding: 0 3rem 0 0;
                font-family: 'SpaceGrotesk Bold';
                color: $primary;
                position: relative;
                display: inline;
                &::after {
                    position: absolute;
                    top: 50%;
                    bottom: 50%;
                    right: 0;
                    content: '';
                    height: 1.5px;
                    width: 40px;
                    background: $primary;
                    margin-left: 20px;
                    transform: translateY(-50%);
                } 
            }
            .soustitre{
                font-size: 2.5rem;
                font-family: 'SpaceGrotesk Bold';
                color: #222429;
            }
            .para1{
                color: #3C3C3B;
                font-family: 'SpaceGrotesk Bold';

            }
            .para2{
                font-size: .8rem;
                font-family: 'SpaceGrotesk Medium';
                color: $primary;
                padding-left: 5px;
                border-left: 3px solid $primary;
            }
            // .btnvoir{
            //     color: white;
            //     background-color: $primary;
            //     border: none;
            //     font-size: 1rem;
            //     padding: 8px 16px;
            //     font-family: 'SpaceGrotesk Bold';
            // }
            @media (max-width: 768px){
                .bloc{
                    margin-top: 1.5rem;
                }
                .soustitre{
                    font-size: 1.8rem;
                }
                .textsous1{
                    font-size: .8rem;
                }
            }
        }

    }
    .section_services{
        background-color: #f1f1f1;
        text-align: center;
        .text1{
            padding: 0 4rem;
            font-size: 1rem;
            color: $primary;
            font-family: 'SpaceGrotesk Bold';
            position: relative;
            display: inline;
            
            
            &::before {
                position: absolute;
                top: 50%;
                bottom: 50%;
                left: 0;
                content: '';
                height: 1.5px;
                width: 50px;
                background: $primary;
                margin-right: 10px;
                transform: translateY(-50%);
            } 
            &::after {
                position: absolute;
                top: 50%;
                bottom: 50%;
                right: 0;
                content: '';
                height: 1.5px;
                width: 50px;
                background: $primary;
                margin-left: 10px;
                transform: translateY(-50%);
            } 
           
        }
        .text2{
            color: $primary;
            font-family: 'SpaceGrotesk Bold';
        }
        .cardstyle{
            padding: 0;
            margin: 0;
            background-color: white;
            box-shadow: none;
        }
        .titre{
            font-size: 15px;
        }
        .btnvoir{
            color: #222429;
            text-align: start;
            font-family: 'SpaceGrotesk Bold';
            text-decoration: none;
            img {
                transform: translateX(0px); 
                transition:  0.6s ease-in-out;

            }
        }
        .card-action-area {
            position: relative;
            
            .btnvoir:hover img {
              transform: translateX(10px); /* Ajustez la valeur selon vos besoins */
            }
          }
          
        @media (max-width: 768px){
            .titre{
                font-size: .9rem;
            }
        }

    }
    .section_partenaires{
        background-color: #fff;
        // .text1{
        //     color: $primary;
        //     font-family: 'SpaceGrotesk Bold';
        //     text-align: center;
        // }
        text-align: center;
        .text1{
            padding: 0 4rem;
            font-size: 1rem;
            color: $primary;
            font-family: 'SpaceGrotesk Bold';
            position: relative;
            display: inline;
            
            
            &::before {
                position: absolute;
                top: 50%;
                bottom: 50%;
                left: 0;
                content: '';
                height: 1.5px;
                width: 50px;
                background: $primary;
                margin-right: 10px;
                transform: translateY(-50%);
            } 
            &::after {
                position: absolute;
                top: 50%;
                bottom: 50%;
                right: 0;
                content: '';
                height: 1.5px;
                width: 50px;
                background: $primary;
                margin-left: 10px;
                transform: translateY(-50%);
            } 
           
        }
        .owl-theme.partener .owl-item .carousel-item {
            display: flex;
            justify-content: center;
          }
          
          .owl-theme.partener .owl-item .carousel-image {
            max-width: 300px;
            max-height: 100px;
            width: auto;
            height: auto;
          }
          @media (max-width: 768px) {
            .owl-theme.partener .owl-item .carousel-image {
              max-width: 200px; /* Ajustez cette taille selon vos besoins */
              max-height: 50px; /* Ajustez cette taille selon vos besoins */
            }
          }
          

    }
    .section_footer{
        .row{
            .souslogo{
                font-family: 'SpaceGrotesk Regular';
                color: #000;
                font-size: 13px; 
            }
            .localisation{
                font-family: 'SpaceGrotesk Bold';
                color: #000;
                font-size: 20px;
            }
            .pays{
                font-family: 'SpaceGrotesk Medium';
                color: #000;
                font-size: 15px;
            }
            .baspays{
                font-family: 'SpaceGrotesk Regular';
                color: #000;
                font-size: 13px;
            }
            @media (max-width: 768px) {
                .localisation{
                    margin-top: 1rem;
                }
              }
        }
    }
    .barre{
        border-bottom: 25px solid $secondary;
    }
}


// @keyframes pulsate {
//     0% {
//       transform: scale(1); /* Taille normale */
//     }
//     50% {
//       transform: scale(1.1); /* Taille légèrement agrandie */
//     }
//     100% {
//       transform: scale(1); /* Retour à la taille normale */
//     }
//   }