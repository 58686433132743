@font-face {
    font-family: "SpaceGrotesk Bold";
    src: url("../../public/fonts/SpaceGrotesk-Bold.ttf") format("opentype")
  }

  @font-face {
    font-family: "SpaceGrotesk Regular";
    src: url("../../public/fonts/SpaceGrotesk-Regular.ttf") format("opentype")
  }
  @font-face {
    font-family: "SpaceGrotesk Medium";
    src: url("../../public/fonts/SpaceGrotesk-Medium.ttf") format("opentype")
  }